import { Typography } from '@material-ui/core';
import cerrarModalLogo from 'assets/images/cerrar-modal.svg';
import volverModalLogo from 'assets/images/boton-volver.svg';
import IconButton from '@material-ui/core/IconButton';
import { InfoIcon, Loading } from '@saesa/ui-kit-front';
import { TIPOCORTE } from 'config/tiposCorte';
import { useTranslation } from 'react-i18next';
import { PATH_MAPA_IFRAME } from 'root-page';
import useStyles from './ModalStyles';

const ModalPoligono = ({
  titulo,
  setTitulo,
  setMostrarModal,
  setMostrarBuscador,
  estadoTracking,
  children,
  dataSinTracking,
  setDataSinTracking,
  setServicioCargado,
  setVentanaMobileState,
  activadorMobile,
}: {
  titulo?: string;
  setTitulo?: any;
  setMostrarModal: any;
  setMostrarBuscador: any;
  estadoTracking?: any;
  children?: any;
  dataSinTracking?: any;
  setDataSinTracking?: any;
  setServicioCargado?: any;
  setVentanaMobileState?: any;
  activadorMobile?: any;
}) => {
  /**
   * Pagina de estilos de componente.
   */
  const styles = useStyles();
  /**
   * Uso del i18n para traducciones.
   */
  const { t } = useTranslation();

  /**
   * Funcion para cerrar ventana modal y abrir buscador
   */
  const cerrarModal = () => {
    setMostrarModal(false);
    setMostrarBuscador(true);
    setServicioCargado(false);
    setTitulo('');
    if (dataSinTracking !== '') {
      setDataSinTracking('');
    }
  };

  /**
   * Funcion para obtener clase según el path
   */
  const obtenerClase = () => {
    if (window.location.pathname === PATH_MAPA_IFRAME) {
      return `${styles.caja} ${styles.cajaIframe}`;
    }

    return styles.caja;
  };

  /**
   * Funcion que permite volver a la ventana mobile
   */
  const volverModal = () => {
    setVentanaMobileState(true);
    setMostrarModal(false);
    setServicioCargado(false);
  };

  /**
   * Retorna el estilo del circulo segun sea el tipo de corte.
   *
   * @param tipo de corte
   * @returns Estilo de circulo asociado a corte
   */
  const tipoCorte = (tipo: string) => {
    let corte = '';
    switch (tipo) {
      case TIPOCORTE.CORTE_IMPREVISTO:
        corte = styles.circuloRojo;
        break;

      case TIPOCORTE.CORTE_PROGRAMADO:
        corte = styles.circuloNaranja;
        break;
      case TIPOCORTE.PROXIMO_CORTE_PROGRAMADO:
        corte = styles.circuloAzul;
        break;

      default:
        break;
    }
    return corte;
  };

  /**
   * Función que se encarga de dar el estilo correspondiente al boton de cerrar la ventana modal dependiendo el tipo de corte
   * @returns el estilo correspondiente para el tipo de corte
   */
  const obtenerContenedorBotones = () => {
    if (titulo !== TIPOCORTE.CORTE_IMPREVISTO || dataSinTracking) {
      return styles.contenedorBoton;
    }
    return styles.contenedorBotonImprevistos;
  };

  /**
   * Muestra vista inferior para informar al cliente cuando no hay información de tracking
   * asociado al corte.
   *
   * @returns Retorna vista para cuando no existe información desde tracking
   */
  const mostrarDataSinTracking = () =>
    dataSinTracking?.length > 1 ? (
      <div className={styles.contenedorMensaje}>
        <div className={styles.contenedorTextoIcono}>
          <InfoIcon className={styles.mensajeIcon} />
        </div>

        <div className={styles.parrafo}>
          <Typography className={styles.textoParrafo}>{t('desconexion:paginas.mapaCortes.modalTracking.infoSinTracking')}</Typography>
        </div>
      </div>
    ) : (
      <Loading />
    );

  const contenedoresBotonesModal = () =>
    activadorMobile ? (
      <div className={styles.contenedorBotonMobile}>
        <IconButton title="cerrarBotom" aria-label="cerrar" onClick={volverModal} size="small" className={styles.sizeSmall}>
          <img src={volverModalLogo} alt="Cerrar" />
        </IconButton>
      </div>
    ) : (
      <div className={obtenerContenedorBotones()}>
        <IconButton title="cerrarBotom" aria-label="cerrar" onClick={cerrarModal} size="small" className={styles.sizeSmall}>
          <img src={cerrarModalLogo} alt="Cerrar" />
        </IconButton>
      </div>
    );

  /**
   * Función que decidi el titulo del modal
   * @returns Retorna titulo del modal
   */
  const tituloModal = () =>
    titulo === TIPOCORTE.PROXIMO_CORTE_PROGRAMADO ? (
      <Typography className={styles.textoTitulo}>{titulo}</Typography>
    ) : (
      <Typography className={styles.textoTitulo}>
        {t('desconexion:paginas.mapaCortes.modalTracking.corteEnCurso', {
          total: titulo,
        })}
      </Typography>
    );
  return (
    <>
      {titulo !== '' ? (
        <div className={obtenerClase()}>
          {contenedoresBotonesModal()}
          {titulo && (
            <>
              <div className={styles.cajaTitulo}>
                {estadoTracking !== '' && <div className={tipoCorte(titulo)} />}
                {dataSinTracking === '' && estadoTracking && tituloModal()}
                {titulo === TIPOCORTE.CORTE_IMPREVISTO && !activadorMobile && (
                  <Typography className={styles.textoTituloImprevisto}>
                    {t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.estadoDeTuServicio')}
                  </Typography>
                )}
                {dataSinTracking && <Typography className={styles.textoTitulo}>{titulo}</Typography>}
              </div>
              {estadoTracking !== '' && <div className={styles.linea} />}
            </>
          )}

          <div className={styles.cuerpo} id="modal-poligono">
            {estadoTracking && <div className={styles.tracking}>{estadoTracking}</div>}
            {children && <div className={styles.children}>{children}</div>}
            {dataSinTracking !== '' && (
              <div className={styles.sinTracking}>
                {dataSinTracking.map((data: any) => (
                  <div className={styles.cajaTextoNoTracking}>
                    <Typography className={styles.textoTitulo}>{data.titulo}:</Typography>
                    <Typography className={styles.textoParrafo}>{data.texto}</Typography>
                  </div>
                ))}
              </div>
            )}
            {mostrarDataSinTracking()}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

ModalPoligono.defaultProps = {
  titulo: '',
  setTitulo: '',
  estadoTracking: '',
  children: '',
  dataSinTracking: '',
  setDataSinTracking: '',
  setServicioCargado: '',
  setVentanaMobileState: '',
  activadorMobile: '',
};

export default ModalPoligono;
