import axios from 'axios';

const mostrarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10) + 1;
  sessionStorage.setItem('loading', contador.toString());

  const loading = document.getElementById('loading');
  return loading?.classList.add('backdrop--show');
};

const AuthInterceptor = () => {
  const apiKey = `${process.env.REACT_APP_HEADER_API_KEY}`;

  /**
   * Constantes para ignorar el token
   */
  const publicIgnorePath = '/public';
  const regexFeatureFlagIgnorePath = '/featureFlag.json';

  axios.interceptors.request.use(request => {
    mostrarLoading();
    const token = localStorage.getItem('token');

    if (!request.url?.includes(regexFeatureFlagIgnorePath)) {
      request.headers['x-api-key'] = apiKey;

      if (!request.url?.includes(publicIgnorePath)) {
        if (!token || request.headers.Authorization) {
          request.headers.Authorization = request.headers.Authorization || null;
        } else {
          request.headers.Authorization = `Bearer ${token}`;
        }
      }
    }

    return request;
  });
};

export default AuthInterceptor;
