import axios from 'axios';
import {
  BusquedaClientResponseModel,
  EnviarReclamoRequestModel,
  EstadoServicioResponseModel,
  TokenRequestDesconexionModel,
  TokenResponseDesconexionModel,
  TrackingCorteEstadosResponseModel,
} from './models';

class DesconexionService {
  /**
   * Obtiene habilitadores generales de feature en la web
   * @returns retorna habilitadores para distintos feature
   */
  static obtenerFeatureFlag(): Promise<any> {
    return axios.get(`${process.env.REACT_APP_ASSETS_URL}/data/featureFlag.json`).then(response => Promise.resolve(response.data));
  }

  /**
   * Metodo que obtiene data del poligono
   * @returns data
   * `${process.env.REACT_APP_CORTE}/${numeroOrden}`
   * mock: https://saesa.mockable.io/v1/public/cortes/orden/123456
   */
  static obtenerRespuestaCorte(numeroOrden: string): Promise<TrackingCorteEstadosResponseModel> {
    return axios.get(`${process.env.REACT_APP_CORTE}/${numeroOrden}`).then(response => Promise.resolve(response.data));
  }

  /**
   * Servicio que realiza busqueda por nro de cliente
   * @param nroCliente numero de cliente
   * @returns data
   */
  static busquedaNroCliente(nroCliente: string): Promise<BusquedaClientResponseModel> {
    return axios
      .get(`${process.env.REACT_APP_DESCONEXION}/public/${nroCliente}/ubicacion`)
      .then(response => Promise.resolve(response.data));
  }

  /**
   * Metodo que obtiene el token de la app
   * @param data request para token
   * @returns token desconexion
   */
  static obtenerTokenDesconexion(data: TokenRequestDesconexionModel): Promise<TokenResponseDesconexionModel> {
    return axios.post(`${process.env.REACT_APP_DESCONEXION}/public/token`, data).then(response => Promise.resolve(response.data));
  }

  /**
   * Metodo que obtiene los datos del cliente
   * @param nroServicio request nroServicio
   * @returns respuesta del estado del servicio
   */
  static obtenerEstadoServicio(nroServicio: string): Promise<EstadoServicioResponseModel> {
    return axios.get(`${process.env.REACT_APP_DESCONEXION}/${nroServicio}/estado`).then(response => Promise.resolve(response.data));
  }

  /**
   * Metodo encargado de generar el ticket de reporte
   * @param data del reclamo
   * @returns respuesta de creación del ticket
   */
  static enviarReclamo(data: EnviarReclamoRequestModel): Promise<any> {
    return axios.post(`${process.env.REACT_APP_DESCONEXION}/reclamo/sinluz`, data).then(response => Promise.resolve(response.data));
  }

  /**
   * Metodo encargado de obtener el estado del ticket
   * @param nroServicio del cliente
   * @param idReclamo a validar
   * @returns respuesta de estado del ticket
   */
  static obtenerEstadoReclamo(nroServicio: string, idReclamo: string): Promise<any> {
    return axios
      .get(`${process.env.REACT_APP_DESCONEXION}/reclamo/sinluz/${nroServicio}/${idReclamo}`)
      .then(response => Promise.resolve(response.data));
  }

  /**
   * Metodo que obtiene el listado de las regiones
   * @returns respuesta del estado del servicio
   */
  static obtenerListadoRegion(): Promise<any> {
    return axios.get(`${process.env.REACT_APP_SUCURSAL}/info/region`).then(response => Promise.resolve(response.data));
  }

  /**
   * Metodo que obtiene el listado de las comunas
   * @param codigoRegion codigo de la region
   * @returns respuesta del estado del servicio
   */
  static obtenerListadoComunas(codigoRegion: string): Promise<any> {
    return axios.get(`${process.env.REACT_APP_SUCURSAL}/info/comuna/${codigoRegion}`).then(response => Promise.resolve(response.data));
  }
}
export default DesconexionService;
