import { createStyles, makeStyles, Theme } from '@material-ui/core';

const AvisoPagoCortesStyles = makeStyles((theme: Theme) =>
  createStyles({
    avisoCorteCnt: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      margin: '0 auto 0 auto',
      background: 'var(--gris-l96)',
      borderRadius: '0.625rem',
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        minWidth: 'auto',
        padding: '16px',
        maxWidth: '422px',
      },
    },
    noVistaAvisoCorteCnt: {
      display: 'none',
    },
    dataCorte: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '0',
      },
    },
    label: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.25rem',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'left',
      },
    },
    item: {
      flexGrow: 41,
      marginBottom: '0.7rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1rem',
      },
    },
    info: {
      textAlign: 'left',
      marginBottom: '0.25rem',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    sinInfo: {
      textAlign: 'left',
    },
    contenedorGrafico: {
      display: 'flex',
      flexDirection: 'column',
      background: 'var(--blanco)',
      boxShadow: '0px 2px 12px 0px rgba(9, 39, 84, 0.14)',
      borderRadius: '5px',
      paddingBottom: '10px',
    },
    grafico: { position: 'relative', display: 'flex', justifyContent: 'center' },
    objetoSobreponer: {
      position: 'relative',
      top: '50px',
      height: '0',
      display: 'flex',
      justifyContent: 'center',
    },
    ovaloGrafica: {
      width: ' 112.32px',
      height: '112.32px',
      borderRadius: '50%',
      display: 'flex',
      background: 'var(--blanco)',
      boxShadow: '0px 1.3061px 13.061px rgba(9, 39, 84, 0.14)',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    porcentajeRecuperados: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: 'var(--gris-l3)',
      order: 1,
      flexGrow: 0,
    },
    circuloVerde: {
      borderRadius: '50%',
      width: '9.14px',
      height: '9.14px',
      background: 'var(--verde-exito)',
      flex: 'none',
      order: 0,
      flexGrow: 0,
    },
    contenedorPorcentaje: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '8px',
    },
    textos: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      textAlign: 'center',
      color: 'var(--gris-l3)',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
    },
    contenedorInternoOvaloGrafica: {
      display: 'flex',
      flexDirection: 'column',
    },
    tituloClientesRecuperados: {
      marginTop: '20px',
      display: 'flex',
      fontSize: '18px',
      fontWeight: 600,
      justifyContent: 'center',
    },
    labelClientesRecuperados: {
      display: 'flex',
      fontSize: '14px',
      justifyContent: 'center',
    },
    contenedorPieDeGrafico: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '6px',
      padding: '1vh',
    },
    circuloRojo: {
      borderRadius: '50%',
      width: '9.14px',
      height: '9.14px',
      background: 'var(--rojo-error)',
    },
    clientesSinServicio: {
      fontSize: '14px',
      lineHeight: '16px',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
  })
);

export default AvisoPagoCortesStyles;
